import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
// import AccouintNav from "../components/account/account-nav";
// import OrderListing from "../components/orders/OrderListing";
// import BreadCrumbs from "../components/common/breadcrumbs/BreadCrumbs";
// import Api from "../services/Api";
import Loader from "../components/form/Loader";
import { useSelector } from "react-redux";

const OrderList = () => {
  const { user } = useSelector((state) => state.auth);

  // const [params, setParams] = useState({
  //   page: 1,
  //   limit: 10,
  // });
  const [url, setUrl] = useState();
  // const [hasMoreData, setHasMore] = useState();
  // const [, setDataLimit] = useState();

  // const { page, limit } = params;

  // const passData = (data, limit) => {
  //   setHasMore(data);
  //   setDataLimit(limit);
  // };

  useEffect(() => {
    if (user?.status === 200) {
      setUrl(
        () => "https://bc.atlantixdigital.com/account.php?action=order_status"
      );
    }
  }, []);

  return (
    <Layout>
      {!url ? (
        <Loader />
      ) : (
        <iframe
          src={url}
          style={{ width: "100%", height: "1000px", border: "none" }}
        />
      )}

      {/* <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <BreadCrumbs location={location} />
          <h1 className="page-title">Order</h1>
        </div>
      </div>

      <div className="container">
        <AccouintNav path={"order-listing"} />

        <div className="account-content">
          <h3 className="account-heading">Orders</h3>
          <OrderListing limit={limit} page={page} hasMoreData={passData} />
          <div style={{ display: "none" }}>
            <OrderListing
              limit={limit}
              page={page + 1}
              hasMoreData={passData}
            />
          </div>
          <button
            disabled={page > 1 ? false : true}
            onClick={() =>
              setParams(() => ({
                ...params,
                page: page - 1,
              }))
            }
          >
            Previous
          </button>
          <button
            disabled={!hasMoreData}
            onClick={() =>
              setParams(() => ({
                ...params,
                page: page + 1,
              }))
            }
          >
            Next
          </button>
        </div>
      </div> */}
    </Layout>
  );
};

export default OrderList;
